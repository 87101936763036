<template>
  <div class="accessList common-page ModuleList">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:用户组管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:模块列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-select
          v-model="pageConfig.productId"
          filterable
          :clearable="true"
          :placeholder="$t('dc:请选择产品')"
          @change="queryApi"
          class=""
          style="margin-right: 10px; width: 240px"
        >
          <el-option
            v-for="item in productList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button class="btn-black" type="info" @click="addModule">{{
          $t('dc:新增模块')
        }}</el-button>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="item.key === 'no'"> {{ scope.$index + 1 }}</span>
            <span v-else-if="item.key === 'status'">Normal</span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          ></el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="120" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑模块')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="showEditModule(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:删除模块')" placement="top-start">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-delete padding12 nicon"
                  @click="showDelModule(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <addEditModuleDialog
      v-if="showAddModuleDialog"
      :visible.sync="showAddModuleDialog"
      :rowData="rowData"
      @queryApi="queryApi"
    ></addEditModuleDialog>
    <UsuallyDialog
      v-if="showDelectModuleDialog"
      :visible.sync="showDelectModuleDialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import addEditModuleDialog from '@/views/dialog/addEditModuleDialog'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'ModuleList',
  mixins: [$PCommon.TableMixin],
  components: {
    addEditModuleDialog,
    UsuallyDialog,
  },
  data() {
    return {
      loading: false,
      showAddModuleDialog: false,
      showDelectModuleDialog: false,
      pageConfig: {
        productId: '',
      },
      title: this.$t('dc:提醒'),
      content: this.$t('dc:您是否确认删除此模块?'),
      rawData: {},
      productList: [],
      tableData: [],
      rowData: {},
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'id',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 30, // 默认宽度，像素
        },
        {
          key: 'productName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: null,
        },
        {
          key: 'firstModuleName',
          name: this.$t('dc:模块名称_1st'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'secondModuleName',
          name: this.$t('dc:模块名称_2st'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'status',
          name: this.$t('dc:状态'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    async initEvent() {
      this.getProductList()
      this.queryApi()
    },
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },

    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.moduleList.getModuleList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.pageConfig.total = data.total
        this.tableData = data.list
      }
      this.loading = false
    },
    addModule() {
      this.rowData = {}
      this.showAddModuleDialog = true
    },
    showEditModule(row) {
      this.rowData = row
      this.showAddModuleDialog = true
    },
    confrom() {
      this.delModule()
    },

    showEdit(row) {
      this.rawData = row
      this.showDialog = true
    },
    showDelModule(row) {
      this.rawData = row
      this.showDelectModuleDialog = true
    },
    async delModule() {
      let { status, data } = await $Api.moduleList.delectModule(this.rawData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
        this.showDelectModuleDialog = false
        this.queryApi(this.pageConfig)
      }
    },
  },
}
</script>

<style lang="scss">
.groupBox {
  display: flex;
  justify-content: space-between;
}
.ModuleList.accessList {
  .access-allocation-dialog .el-table .cell.el-tooltip {
    // white-space: normal;
    span {
      display: inline-block;
    }
  }
  .disable {
    cursor: not-allowed;
    color: #ccc;
  }
}
</style>
